.backdrop {
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 740px;

    .overlay {
        background-image: radial-gradient(closest-corner, rgb(34 34 34 / 80%), rgb(0 0 0 / 80%));
        position: absolute;
        z-index: 10;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        backdrop-filter: blur(32px);
    }
    
    .rive {
        position: absolute;
        z-index: 0;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: 0 auto;
    }
}

.detailSectionOne {
    background-color: white;
    color: black;

    h2 {
        color: black;
    }

}

.deviceImage {
    position: absolute;
    z-index: 9999;
    bottom: -20px;
    right: 74px;

    @media (max-width: 769px){
        display: none;
    }
}

.mainSection {
    @media (max-width: 769px){
        height: 325px;
    }
}
