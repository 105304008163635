@import 'components/global.scss';

.hero {

  position: relative;
  color: white;
  text-align: left;
  padding-top: $scale;
  height: 620px;

  h1 {

    display: block;
    font-size: $scale3;
    font-weight: 800;
    margin-bottom: 0;

  }

  h2 {

    display: block;
    color: white;
    font-weight: normal;
    font-size: $scale1;
    margin: $scale-1 0 $scale;
    line-height: $scale3;

  }

  img {

    margin-bottom: -$scale2;
    margin-top: $scale8;
    box-shadow: 0 $scale $scale1 rgba(0, 0, 0, 0.2);

  }

  &:before {

    content: '';
    position: absolute;
    top: -$scale9;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -100;
    background-color: $c-dark;
  }

  @media (max-width: 769px){

    text-align: center;
    padding-top: $scale5;

    h1 {

      font-size: $scale4;

    }

    h2 {

      font-size: $scale1;

    }
  }
}

.contentSection {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1000;
}

.blurb {

  margin-bottom: $scale3;
  text-align: left;

  @media (max-width: 769px){

    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    width: 100% !important;
    text-align: center;
    margin-bottom: 0;

  }

  @media (min-width: 50em){
    margin-bottom: $scale5;
    margin-top: $scale3;
  }
}

.contentWidth {
  width: 45%;
}

.imageWidth {
  width: 50%;

  @media (max-width: 769px){

    width: 100% !important;
    margin-left: auto;
    margin-right: auto;

    img {
      margin-top: 10px;
      width: 65%;
      margin-left: auto;
      margin-right: auto;
    }

  }
}

.floatRight {
  float: right;
}

.floatLeft {
  float: left;
}

.textCenter {
  text-align: center !important;
}
